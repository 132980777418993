import { render, staticRenderFns } from "./SavedSearchMenu.vue?vue&type=template&id=068316d7&scoped=true&"
import script from "./SavedSearchMenu.vue?vue&type=script&lang=js&"
export * from "./SavedSearchMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068316d7",
  null
  
)

export default component.exports