import { render, staticRenderFns } from "./SavedSearchSaveDialog.vue?vue&type=template&id=12a0e194&scoped=true&"
import script from "./SavedSearchSaveDialog.vue?vue&type=script&lang=js&"
export * from "./SavedSearchSaveDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a0e194",
  null
  
)

export default component.exports